<template>
  <div class="order-box-wrap" v-bind:class="{'no-buttons': !showButtons, 'under-review': orderItem.status === 'under_review', 'pending-labo': !isPickupModeOn && order.status === 'created' && !isClinicLoggedIn && orderItem.status !== 'under_review', 'pending-clinic': !isPickupModeOn && order.status === 'pending' && isClinicLoggedIn && orderItem.status !== 'under_review'}">
    <div class="row-wrap">
      <div class="cell-small">
        <button class="order-action-btn review-btn"
          v-if="orderItem.status === 'under_review' && isClinicLoggedIn && !isPickupModeOn"
          @click="openOrderDetail(order.id)">
            <span class="icon-review"></span>
        </button>
        <button class="order-action-btn" v-bind:class="{'review-btn': orderItem.status === 'under_review'}" v-if="(orderItem.status !== 'under_review' || isLabLoggedIn) && !isPickupModeOn"
          @click="openOrderDetail(order.id)">
            <span class="icon icon-view-eye"></span>
        </button>
        <button class="order-action-btn check" v-bind:class="{'checked': order.selected}" v-if="isPickupModeOn"
          @click="setCheckboxValue(order)">
            <span v-if="order.selected" class="icon-check"></span>
        </button>
      </div>
      <div class="cell-medium"><p>{{order.order_number}}</p></div>
      <div class="cell-large-percentage">
        <p><b>{{orderItem.product_name}}</b></p>
        <p><b>{{order.created_at.split('T')[0]}}</b> (Ultima actualización: {{order.updated_at.split('T')[0]}})</p>
      </div>
      <div class="cell-large"><p>{{orderItem.first_name + ' ' + orderItem.last_name}}</p></div>
      <div class="cell-large" v-if="isClinicLoggedIn"><p>{{orderItem.laboratory_name}}</p></div>
      <div class="cell-large" v-if="isLabLoggedIn"><p>{{order.clinic_name}}</p></div>

      <div class="cell-large"><p>XX/XX/XXX<!--{{(orderItem.file_number == null) ? 'no numero' : orderItem.file_number}}--></p></div>
      <div class="cell-small"><p>{{order.total_price / 100}} €</p></div>
    </div>
    <div class="alert-wrap" v-if="orderItem.status === 'under_review'">
      <p v-if="isClinicLoggedIn">El laboratorio necesita aclaraciones. Por favor revisa tu pedido</p>
      <p v-if="isLabLoggedIn">Por favor revisa y acepta el pedido antes de empezar la producción</p>
    </div>
    <div class="alert-wrap cta-wrap" v-if="!isPickupModeOn && order.status === 'created' && !isClinicLoggedIn && orderItem.status !== 'under_review'">
      <p>Please accept order to enable pickup requests</p>
    </div>
    <div class="alert-wrap pending-wrap" v-if="!isPickupModeOn && order.status === 'pending' && isClinicLoggedIn && orderItem.status !== 'under_review'">
      <p>Su pedido no ha sido aceptado por el laboratorio todavía</p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'OrderCard',
  props: {
    order: {
      type: Object,
      default: null
    },
    selectedStateFilter: {
      type: String
    },
    isPickupModeOn: {
      type: Boolean
    },
    showButtons: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters({
      isClinicLoggedIn: 'user/isClinicLoggedIn',
      isLabLoggedIn: 'user/isLabLoggedIn'
    }),
    showPickUpBtn () {
      if (this.isClinicLoggedIn && this.selectedStateFilter === 'pendiente-clinic') {
        return true
      }
      if (this.isLabLoggedIn && this.selectedStateFilter === 'pendiente-lab') {
        return true
      }

      return false
    }
  },
  data () {
    return {
      orderItem: null
    }
  },
  methods: {
    setCheckboxValue (order) {
      order.selected = !order.selected
      this.$emit('set-checkbox-value', order)
    },
    openPickupMode (order) {
      this.$emit('open-pickup-mode', order)
    },
    openOrderDetail (orderId) {
      if (this.isClinicLoggedIn) {
        this.$router.push({ name: 'ClinicOrderDetail', params: { orderId: orderId } })
      }
      if (this.isLabLoggedIn) {
        this.$router.push({ name: 'LaboratoryOrderDetail', params: { orderId: orderId } })
      }
    },
    initOrderItem () {
      let i = 0
      for (i = 0; i < this.order.order_items.length; i++) {
        if (!this.order.order_items[i].complementary) {
          this.orderItem = this.order.order_items[i]
          break
        }
      }
    }
  },
  created () {
    this.initOrderItem()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  .order-box-wrap{
    height: 70px;
    width: 100%;
    border-radius: 8px;
    background-color: #F6F4EF;
    padding: 10px;
    margin-bottom: 14px;
    &.no-buttons{
      .order-action-btn{
        display: none;
      }
    }
    &.under-review{
      height: 90px;
      background-color: #FDD6D6;
      margin-bottom: 40px;
    }
    &.pending-labo{
      height: 90px;
      background-color: rgba(46, 139, 87, .2);
      margin-bottom: 40px;
    }
    &.pending-clinic{
      height: 90px;
      background-color: rgba(227, 145, 79, .2);
      margin-bottom: 40px;
    }
    .row-wrap{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      div{
        max-height: 100%;
        align-self: center;
        p{
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      .cell-small{
        width: 80px;
      }
      .cell-medium{
        width: 120px;
      }
      .cell-large{
        width: 170px;
      }
      .cell-large-percentage{
        width: calc(100% - 740px);
      }
      .order-action-btn{
        position: relative;
        height: 50px;
        width: 50px;
        border-radius: 8px;
        background-color: #57567C;
        border: 0px;
        color: #FFF;
        font-size: 1.3rem;
        cursor: pointer;
        &.check{
          background-color: transparent;
          border: 4px solid #57567C;;
          color: #57567C;
          &.checked{
            background-color: #57567C;
            color: #FFF;
          }
        }
        .icon-review{
          font-size: 1.7rem;
        }
        .icon-view-eye{
          position: relative;
          top: 2px;
        }
        &.review-btn{
          &:after{
            content: '1';
            display: block;
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: #EA4B4B;
            color: #FFF;
            position: absolute;
            top: -7px;
            right: -7px;
            font-size: .9rem;
            line-height: 20px;
          }
        }
      }
    }
      // width: calc(100% - 10px);
    .alert-wrap{
      position: relative;
      left: -10px;
      width: calc(100% + 20px);
      padding: 8px 0px;
      background-color: #EA4B4B;
      text-align: center;
      color: #FFF;
      border-radius: 0px 0px 8px 8px;
      &.cta-wrap{
        background-color: seagreen;
      }
      &.pending-wrap{
        background-color: #E3914F;
      }
    }
  }
</style>
